import * as icons from "../icons";
import readme from "./textFiles/readme";

const accessories = [
  {
    title: "Minesweeper",
    icon: icons.minesweeper16,
    component: "IframeWindow",
    data: {
      src: "https://98.js.org/programs/minesweeper/"
    },
    multiInstance: true
  },

  {
    title: "SkiFree",
    icon: icons.skifree,
    component: "IframeWindow",
    data: {
      src: "https://basicallydan.github.io/skifree.js/"
    },
    multiInstance: true
  },
  {
    title: "DOOM",
    icon: icons.doom,
    component: "IframeWindow",
    data: {
      src: "/programs/doom/",
      maximizeOnOpen: true
    },
    multiInstance: false
  },
  {
    title: "DUKE3D",
    icon: icons.duke,
    component: "IframeWindow",
    data: {
      src: "/programs/duke3d/",
      maximizeOnOpen: true
    },
    multiInstance: false
  },
  {
    title: "Solitaire",
    icon: icons.solitaire,
    component: "IframeWindow",
    data: { src: "https://98.js.org/programs/js-solitaire/"},
    multiInstance: true
  },
];

const programs = [
  { title: "Games", icon: icons.folderProgram16, options: accessories },
  {
    title: "Archive",
    icon: icons.olddonk,
    component: "IframeWindow",
    data: { src: "/programs/archive/", maximizeOnOpen: true },
    multiInstance: true,
  },
  {
    title: "JS-DOS Prompt",
    icon: icons.msDos16,
    component: "JSDos",
    multiInstance: true
  },


  {
    title: "Notepad",
    icon: icons.notepad16,
    component: "Notepad",
    multiInstance: true
  },
  {
    title: "Paint",
    icon: icons.paint16,
    component: "IframeWindow",
    data: { src: "/programs/paint" },
    multiInstance: true
  },
  {
    title: "Calculator",
    icon: icons.calculator16,
    component: "IframeWindow",
    data: { src: "https://98.js.org/programs/calculator/", width: "400", height: "400" },
    multiInstance: true
  },
  {
    title: "Pipes",
    icon: icons.pipes,
    component: "IframeWindow",
    data: { src: "/programs/pipes", maximizeOnOpen: true },
    multiInstance: false
  },
  {
    title: "3D FlowerBox",
    icon: icons.pipes,
    component: "IframeWindow",
    data: { src: "/programs/3D-FlowerBox", maximizeOnOpen: true },
    multiInstance: false
  },
];

const favorites = [
  {
    title: "Instagram",
    type: "ExternalLink",
    icon: icons.instagram,
    href: "https://instagram.com/donk.world"
  },
  {
    title: "Facebook",
    type: "ExternalLink",
    icon: icons.facebook,
    href: "https://www.facebook.com/donkworldofficial/"
  },
  {
    title: "Bandcamp",
    type: "ExternalLink",
    icon: icons.bandcamp,
    href: "https://donkworld.bandcamp.com/"
  },
  {
    title: "YouTube",
    type: "ExternalLink",
    icon: icons.youtube,
    href: "https://www.youtube.com/channel/UCyI4fQ7kwBUUTmAapqA_8Pg"
  }
];

export const find = [
];

const startMenuData = [
  {
    title: "DONK.WORLD | LINKS",
    icon: icons.internetExplorere32,
    component: "IframeWindow",
    data: { src: "/programs/littlelink/", maximizeOnOpen: true},
    multiInstance: true,
  },
  {
    title: "Programs",
    icon: icons.folderProgram24,
    options: programs
  },
  {
    title: "Music Player",
    icon: icons.cd32,
    component: "Player",
    multiInstance: true
  },
  {
    title: "Calendar",
    icon: icons.calendar,
    component: "IframeWindow",
    data: {
      src: "https://calendar.google.com/calendar/embed?src=6bo6hfdn7bfgqrsm7n5agmtcbk%40group.calendar.google.com&ctz=Australia%2FSydney",
      maximizeOnOpen: false,
    },
    multiInstance: true,
  },
  {
    title: "Socials",
    icon: icons.folderFavorites24,
    options: favorites,
  },
  {
    title: "READ ME",
    icon: icons.notepadFile32,
    component: "Notepad",
    data: {
      content: readme,
      readOnly: true,
      width: "680",
      height: "200",
    },
  },
  {
    title: "Email us",
    icon: icons.outlook32,
    onClick: () => window.open("mailto:website-contact@donk.world"),
  },
];

export default startMenuData;
